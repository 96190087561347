import XHR from '@/request/http'
const $h = new XHR()
/**
 * 模板相关
 * 
 * 
 */

// url
const url = {
    rule_lists:'/admin/v1/lesson/rules/page',
    rule_update:'/admin/v1/lesson/rules/update',
    rule_create:'/admin/v1/lesson/rules/create',
    rule_del:'/admin/v1/lesson/rules/del',
    get_rule:'/admin/v1/lesson/rules/get',
    list:'/admin/v1/lesson/template/list',
    lists:'/admin/v1/lesson/template/page',
    update:'/admin/v1/lesson/template/update',
    create:'/admin/v1/lesson/template/create',
    del:'/admin/v1/lesson/template/del',
    get_module:'/admin/v1/lesson/template/info', // 获取模板
    clone:'/admin/v1/lesson/template/clone',
    clone_rule:'/admin/v1/lesson/rules/clone',

    module_add_rule:'/admin/v1/lesson/template/rules/add', //将指标项添加到模板中
    module_del_rule:'/admin/v1/lesson/template/rules/remove', //将指标项从模版中移除
    module_edit_rule:'/admin/v1/lesson/template/rules/update', //编辑模版中的指标项
}

export async function lists(data)
{
    return $h.request(url.lists,data,'GET')
}
export async function create(data)
{
    return $h.request(url.create,data,'POST')
}

export async function update(data)
{
    return $h.request(url.update,data,'POST')
}

export async function del(data)
{
    return $h.request(url.del,data,'POST')
}

//获取模板
export async function get_module(id)
{
    return $h.request(url.get_module,{templateId:id},'GET')
}

export async function module_add_rule(data)
{
    return $h.request(url.module_add_rule,data,'POST')
}

export async function module_del_rule(data)
{
    return $h.request(url.module_del_rule,data,'POST')
}

export async function module_edit_rule(data)
{
    return $h.request(url.module_edit_rule,data,'POST')
}


export async function rule_lists(data)
{
    return $h.request(url.rule_lists,data,'GET')
}

export async function rule_create(data)
{
    return $h.request(url.rule_create,data,'POST')
}

export async function rule_update(data)
{
    return $h.request(url.rule_update,data,'POST')
}

export async function rule_del(data)
{
    return $h.request(url.rule_del,data,'POST')
}


export async function get_rule(id)
{
    return $h.request(url.get_rule,{id:id},'GET')
}
export async function clone(data)
{
    return $h.request(url.clone,data,'POST')
}


export async function clone_rule(data)
{
    return $h.request(url.clone_rule,data,'POST')
}

export async function list(data)
{
    return $h.request(url.list,data,'GET')
}