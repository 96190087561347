<template>
   <div>
        <el-button type="primary" style="width: 100%;" @click="handleCreate">+ 创建示例</el-button>
        <el-collapse>
            <el-collapse-item v-for="item,index in data" :key="index" :title="item.title" :name="index">
                <div style="background-color: rgba(0, 0, 0, 0.1);padding: 10px;">
                    <div class="em-title d-flex a-center j-sb" style="margin-bottom: 5px;">
                        <el-input v-model="item.title" placeholder="示例名称" class="flex-1"></el-input>
                        <el-button type="primary" plain class="ml-1" @click="handleDelete(index)">删除示例</el-button>
                    </div>
                    <el-input type="textarea" v-model="item.content" rows="10" placeholder="课程文本内容"></el-input>

                    <el-table
                        :data="item.result"
                        size="mini"
                    >
                        <el-table-column prop="name" label="指标"></el-table-column>
                        <el-table-column prop="" label="得分" align="center" width="100">
                            <template slot-scope="scope">
                                <el-input type="number" :min="0" v-model="scope.row.score" placeholder="分数"/>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="ai打分依据" align="center">
                            <template slot-scope="scope">
                                <el-input type="text" v-model="scope.row.basis" placeholder="打分依据"/>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-collapse-item>
        </el-collapse>   
   </div>
</template>

<script>
export default {
    props:{
        rules:{
            type:Array,
            default:function(){
                return []
            }
        },
        content:{
            type:Array,
            default:function(){
                return []
            }
        }
    },
    data(){
        return {
            data:[],
            rule_lists:[],//所有已选指标
        }
    },
    watch:{
        rules:{
            handler:function(val) {
                this.getRuleAll(val)
            },
            deep:true,
        },
        content:function(val) {
            
            let _data = [];
            val && val.forEach(item=>{
                let _d = {
                    'content': item.content,
                    'title': item.title,
                    'result': JSON.parse(item['result'])
                };
                _data.push(_d)
            })
            this.data = _data
        },
    },
    created(){
        
    },
    methods:{
        handleCreate(){
            this.create()
        },
        handleDelete(index){
            this.data.splice(index,1)
        },
        //创建
        create(){
            let _json = {
                    title:'示例名称',
                    content:'',
                    result:[
                        // {name:'',score:0,basis:''}
                    ]
                };
            let _result = [];
            this.rule_lists.length && this.rule_lists.forEach(item=>{
                _result.push({name:item.name,score:'',basis:''})
            })
            _json.result = _result
            console.log(this.data)
            this.data.push(_json)
        },

        //获取所有的指标
        getRuleAll(rules){
            let _r = [];
            rules && rules.forEach(item => {
                item.items && item.items.forEach(itx=>{
                    let _index = _r.findIndex(element=>element.id==itx.id)
                    if(_index<0) {
                        _r.push(itx)
                    }
                    
                })
            });
            this.rule_lists = _r;
            //源数据变化，响应
            this.reactiveRule();
        },
        
        reactiveRule(){
            this.data.forEach(item=>{
                let _arr = []
                this.rule_lists.forEach(itx=>{
                    let _index = item.result.findIndex(element=>element.name==itx.name)
                    if(_index>-1) {
                        //优先result
                        _arr.push(item.result[_index])
                    }else{
                        _arr.push({name:itx.name,score:'',basis:''})
                    }
                })
                item.result = _arr
            })
        },
        get_data(){
            let _data = [];
            this.data.forEach(item=>{
                let _item = JSON.parse(JSON.stringify(item))
                _item.result = JSON.stringify(_item.result);
                _data.push(_item)
            })
            return _data
        }
    }
}
</script>

<style scoped>
/deep/ .el-collapse-item__header {
    border-bottom:1px solid #d5d5d5!important;
    padding-left: 10px;
}
</style>