<template>
    <el-drawer
        title="编辑"
        :visible.sync="drawer"
        :modal="true"
        :wrapperClosable="false"
        size="80%"
        @close="closeDrawer"
        :modal-append-to-body="false"
        :direction="direction">

        <!-- title -->
        <div class="drawer_form">
            
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="120px" class="demo-ruleForm">
                <div class="form-body">
            
                    <div style="width: 45%;position: absolute;left: 0;height: 100%;">
                        <el-form-item label="模板名称" prop="">
                            <el-input type="text" rows="6" v-model="form.template.templateName" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="评课附加提示词" prop="">
                            <el-input type="textarea" rows="6" v-model="form.template.background" autocomplete="off"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="专家评分占比" prop="">
                            <el-input type="text" rows="6" v-model="form.template.expertWeight" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="机器评分占比" prop="">
                            <el-input type="text" rows="6" v-model="form.template.machineWeight" autocomplete="off"></el-input>
                        </el-form-item>

                        <el-form-item label="模板名称" prop="">
                            <el-select v-model="form.template.reviewType" class="w-full" placeholder="请选择">
                                <el-option
                                v-for="item in review_type"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                        <div style="position: absolute;;left: 0%;right: 0;top: 220px;bottom: 0;">
                            <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                                <div style="margin-left: 10px;">
                                    
                                    <CreateExample ref="example" :content="samples" :rules="rule_selected_lists"/>  
                                </div>
                            </overlay-scrollbars>
                        </div>
                        
                    </div>
                
                    <div style="position: absolute;left: 46%;right: 0;top: 0;bottom: 0;background-color: #f2f2f2;">
                        <div v-if="!editable" style="background: rgba(0, 0, 0, 0.1);user-select: none;width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: 999;display: flex;align-items: center;justify-content: center;">禁止编辑</div>
                        <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                            <el-card v-if="editable" shadow="hover" style="margin: 15px 15px;text-align: center;cursor: pointer;user-select: none;">
                               <div style="width: 100%;height: 100%;" @click="handleAddGroup"> + 添加</div>
                            </el-card>
                            <el-card v-for="item,index in rule_selected_lists" :key="index" shadow="hover" style="margin: 15px 15px;">
                                <div class="d-flex">
                                    <el-input type="text" v-model="item.groupName" placeholder="指标项名称"/>
                                    <el-popconfirm
                                        title="确定删除吗？"
                                        @confirm="handleGroupDel(item,index)"
                                    >
                                        <el-button slot="reference" type="primary" plain>删除</el-button>
                                    </el-popconfirm>
                                </div>
                                <div style="margin-top: 6px;">
                                    <el-select 
                                    filterable
                                    remote
                                    reserve-keyword
                                    placeholder="关联指标,支持模糊查询"
                                    :remote-method="remoteMethodRule"
                                    @change="(val)=>handleChange(val,item)"
                                    v-model="item.lists"
                                    multiple class="w-full">
                                        <el-option
                                        v-for="item in rule_lists"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                                <el-table
                                    :data="item.items"
                                    size="mini"
                                >
                                <el-table-column prop="name" label="指标" align="center"></el-table-column>
                                <el-table-column prop="" label="分数" align="center">
                                    <template slot-scope="scope">
                                        <el-input type="number" :min="0" v-model="scope.row.maxScore" placeholder="分数"/>
                                    </template>
                                </el-table-column>
                                </el-table>
                            </el-card>
                        </overlay-scrollbars>
                    </div>
                </div>

                <div class="form-foot d-flex a-center j-center">
                    <el-button @click="onSubmit" type="primary" class="" style="width:200px">提交</el-button>
                </div>
            </el-form>
        </div>

    </el-drawer>
    
</template>

<script>
import {update,rule_lists,get_module,module_del_rule} from "@/request/api/module.js"
import {lists} from '@/request/api/expert.js'
import CreateExample from './CreateExampleComponent'
export default {
    components:{
        CreateExample
    },
    data(){
        return {
            drawer: true, //表单
            direction: 'rtl',//抽屉方向
            loading:false,
            form:{
                rules:[
                    // {
                    //     items:[],
                    //     groupName:'',
                    //     // description:'',
                    //     // maxScore:'',
                    // }
                ],
                template:{
                    id:'',
                    templateName:'', //名称
                    background:'',//文化背景
                    // expertIds:[],
                    // expertWeight:'',//专家评分占比
                    // machineWeight:'',//机器评分占比
                    // reviewType:'',//评级类型：1机评、2专家评、3人机协同
                }
            },
            rule_selected_lists:[],//选择的指标
            expert_query:{keyword:'',page:1,size:50},
            rule_query:{name:'',page:1,size:100},
            review_type:[{label:'机评',value:1},{label:'专家评',value:2},{label:'人机协同',value:3}],
            rule_lists:[],//指标项
            expert_lists:[], // 专家列表
            editable:true, //判断指标项是否可编辑
            samples:[],
            rules:{
                'rules.groupName':[
                    { required: true, message: '名称必填', trigger: 'blur' },
                ],
            }
        }
    },
    created(){
        this.form.template.id = this.$route.query.id || ''
        this.init()
    },
    methods:{
        async init(){
            this.get_experts()
            let {data} = await this.get_rules()
            this.rule_lists = data.list
            get_module(this.form.template.id).then(res=>{
                let _t = res.data.template
                let _r = res.data.rules
                let _rule = this.rule_lists;
                this.editable = res.data.editable
                this.samples = res.data.samples
                // let _rule_ids = _rule.map(item=>item.id)
                _r.forEach(item=>{
                    item['items'] = []
                    item['lists'] = []
                    if(item.children) {
                        item.children.forEach(itx=>{
                            // if(!_rule_ids.includes(itx.id)) {
                            //     //写入
                            //     _rule.unshift(itx)
                            // }
                            let _rule_d = _rule.find(_res=>_res.id==itx.id)
                            if(!_rule_d) {
                                //插入
                                _rule.push(itx)
                            }
                        })
                        item['items'] = item.children
                        item['lists'] = item.children.map(obj=>obj.id)
                    }
                    
                    
                })
                Object.keys(this.form.template).forEach(item=>{
                    if(typeof _t[item] != 'object') {
                        this.form.template[item] = _t[item]
                    }
                })
                // console.log(_rule)
                // this.form.template.expertIds = _t.expertList.map(itx=>itx.id);
                this.form.rules = _rule
                this.rule_selected_lists = _r
            })
        },
        //专家列表
        get_experts(){
            lists(this.expert_query).then(res=>{
                this.expert_lists = res.data.list
            })
        },
        //指标列表
        async get_rules(){
           return await rule_lists(this.rule_query)
        },

        //添加组
        handleAddGroup(){
            this.rule_selected_lists.push({groupName:'',items:[],lists:[]})
        },

        //切换
        handleChange(val,item){
            // console.log(val)
            if(val.length==0) {item.lists=[]}
            let data = item.items;
            let arr = {};
            val.forEach(itx => {
                let _data = data.find(idx=>idx.id==itx)
                if(!_data) {
                    let _r = this.rule_lists.find(idx=>idx.id==itx)
                    data.push(_r)
                    arr[itx]=_r
                }else{
                    //去掉多余的
                    arr[itx]=_data
                }
            });       
            item.items =  Object.values(arr)
        },

        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.req()
                } else {
                    return false;
                }
            });
        },

        //删除组
        handleGroupDel(item,index){
            module_del_rule({ids:[item.id]}).then(res=>{
                if(res.code!=0) return this.$notify({title: '失败',message:res.msg,type: 'error'});
                this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
                this.rule_selected_lists.splice(index,1)
            })
        },
        
        remoteMethod(query) {
            this.expert_query.keyword = query
            this.get_experts()
        },
        remoteMethodRule(query) {
            this.rule_query.name = query
            this.get_rules()
        },
        //req
        req(){
            let rules = [];
            this.rule_selected_lists.forEach(item=>{
                let data = {
                    groupName:item.groupName,
                    templateId:item.templateId,
                    id:item.id,
                    items:[]
                }
                item.items.forEach(itx=>{
                    let rule_itx = {
                        id:itx.id,
                        maxScore:itx.maxScore || 0
                    };
                    data.items.push(rule_itx)
                })

                rules.push(data)
            })
            let form = JSON.parse(JSON.stringify(this.form));
            // form.template.expertIds = form.template.expertIds.join(',');
            if(!this.editable) {
                delete form.rules
            }else{
                form.rules = rules
            }
            form.samples = this.$refs.example.get_data()
            // console.log(form)
            update(form).then(res=>{
                if(res.code!=0) return this.$notify({title: '失败',message:res.msg,type: 'error'});
                this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
                this.$router.go(-1)
            }) 
        },

        

        //重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        //关闭抽屉
        closeDrawer(){
            this.$router.go(-1)
        }
    }

}
</script>


<style lang="less" scoped>
.drawer_form {
    width: 96%;
    margin: auto;
    .drawer_form_title {
        color: #17233d;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        line-height: 48px;
        margin-bottom: 20px;
    }

    .drawer_line {
        margin-bottom: 30px;
        .drawer_line_text {
            width: 120px;
            margin-right: 20px;
            text-align: right;
            color: #515a6e;
        }    
    }

}
.form-items {
    background-color:#f5f6fb;padding:15px 10px;height:500px;
}

/deep/ .form-items .el-form-item ,/deep/ .form-items .el-form-item .el-form-item__content{
    width: 100%;
}

</style>

